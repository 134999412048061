<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol sm="auto"
        ><div class="ui-type-display-lg">{{ $t('family.invoices.title') }}</div></CCol
      >
    </CRow>

    <CCard>
      <CCardBody>
        <MarketInvoicesFilters
          ref="filters"
          :filters="filters"
          @onSearch="onSearch(filters)"
        />
        <MarketInvoicesTable :filters="filters" ref="invoices" />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import MarketInvoicesFilters from "./partials/MarketInvoicesFilters";
import MarketInvoicesTable from "./partials/MarketInvoicesTable";
import subMonths from "date-fns/subMonths";
import EventBus from "../../helpers/EventBus";

export default {
  name: "UiMarketInvoices",

  components: {
    MarketInvoicesFilters,
    MarketInvoicesTable,
  },

  data() {
    return {
      filters: {
        search: "",
        start: subMonths(new Date(), 12),
        end: new Date(),
      },
    };
  },

  watch: {
    filters: {
      handler(val) {
        this.onSearch(val);
      },
      deep: true,
    },
  },

  mounted() {
    EventBus.$on("invoices:refresh", this.refresh);
  },

  beforeDestroy() {
    EventBus.$off("invoices:refresh", this.refresh);
  },

  methods: {
    onSearch(filters) {
      this.$refs.invoices.doSearch(filters);
    },

    refresh() {
      this.onSearch(this.filters);
    },
  },
};
</script>

<template>
  <form @submit.prevent="onSearch" class="filters">
    <CRow class="align-items-end">
      <CCol md="5">
        <CInput
          :label="$t('common.search_label')"
          :value.sync="filters.search"
          :placeholder="$t('family.invoices.filters.search_placeholder')"
        >
          <template #prepend-content>
            <font-awesome-icon icon="search" />
          </template>
        </CInput>
      </CCol>
      <CCol md="3">
        <UiDatePicker
          :label="$t('family.invoices.filters.from')"
          :date.sync="filters.start"
          :clearButton="false"
          :readonly="true"
          :max-date="new Date()"
          :masks="masks"
        />
      </CCol>
      <CCol md="3">
        <UiDatePicker
          :label="$t('family.invoices.filters.to')"
          :date.sync="filters.end"
          :clearButton="false"
          :readonly="true"
          :min-date="filters.start"
          :max-date="new Date()"
          :masks="masks"
        />
      </CCol>
      <!-- <CCol col="auto">
        <CButton class="mb-3" color="primary" type="submit">
          <font-awesome-icon icon="search" />
        </CButton>
      </CCol> -->
    </CRow>
  </form>
</template>

<script>
import UiDatePicker from "../../../common/form/UiDatePicker";

export default {
  name: "InvoicesInFilters",

  components: {
    UiDatePicker,
  },

  props: {
    filters: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      masks: {
        input: "DD/MM/YYYY",
      },
    };
  },

  methods: {
    onSearch() {
      this.$emit("onSearch", { ...this.filters });
    },
  },
};
</script>
